import AssignmentService from "@/core/services/assignement/AssignmentService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CoursesService from "@/core/services/courses/CoursesService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import AssignmentQuestionService from "@/core/services/assignement/AssignmentQuestionService";
const assignmentQuestionService = new AssignmentQuestionService();
const gradeLevelService = new GradeLevelService();
const courseService = new CoursesService();
const programService = new ProgramService();
const assignmentService = new AssignmentService();


export const AssignmentMixin = {
    data() {
        return {
            search: {
                program_id: null,
                grade_id: null,
            },
            programs: [],
            courses: [],
            gradesLevels: [],
            assignments: [],
            assignment_questions: [],
            assignments_accpeted_files: [],
            isBusy: false,
            return_back: false,
            page: null,
            total: null,
            perPage: null,
            assignment: {
                title: null,
                description: null,
                feedback_types: [],
                program_id: null,
                grade_id: null,
                course_id: null,
                added_by: null,
                type: null,
                added_date: null,
                due_date: null,
                is_active: true
            }
        }
    },
    computed: {
        assignment_id() {
            return this.$route.params.assignment_id;
        },
    },
    methods: {
        createAndUpdate() {
            if (this.lesson_plan){
                this.assignment.course_id = this.lesson_plan.course_id;
            }
            if (this.assignment.accepted_files)
                this.assignment.accepted_files = this.assignment.accepted_files.join(',')
            if (this.assignment.feedback_types)
                this.assignment.feedback_types = this.assignment.feedback_types.join(',')
            this.$v.$touch();
            if (this.$v.assignment.$error) {
                setTimeout(() => {
                    this.$v.$reset();
                }, 3000);
            } else {
                if (this.assignment.id) {
                    this.__update();
                } else {
                    this.__create();
                }
            }
        },
        createAndUpdateReturn() {
            this.return_back = true;
            this.createAndUpdate();
        },
        returnBack() {
            this.$router.push({
                name: 'course-summary', params: {
                    lesson_plan_id: this.assignment.lesson_planner_id
                }

            })
        },
        __create() {
            this.isBusy = true;
            assignmentService.store(this.assignment).then(response => {
                this.isBusy = false;
                if (this.return_back)
                    this.returnBack();
                this.resetForm()
                this.$snotify.success("Information added");
            });
        },
        __update() {
            this.isBusy = true;
            assignmentService.update(this.assignment.id, this.assignment).then(response => {
                this.isBusy = false;
                if (this.return_back)
                    this.returnBack();
                this.resetForm()
                this.$snotify.success("Information updated");

            });
        }, resetForm() {
            this.assignment = {
                level_id: null,
                program_id: null,
                title: null,
                start_date: null,
                end_date: null,
                student_visibility:false,
                is_active: true
            }
            this.edit = false
            this.$v.$reset()
            this.getSingleAssignment(this.assignment_id)
            this.$emit("refresh");
        },
        getAssignments() {
            this.isBusy = true;
            this.$bus.emit('toggleLoader');
            assignmentService.paginate(this.search, this.page).then(response => {
                this.assignments = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            }).catch((err) => {
            }).finally(() => {
                this.$bus.emit('toggleLoader');
                this.isBusy = false;

            });
        },
        getAssignmentAcceptedFiles() {
            this.isBusy = true;
            this.$bus.emit('toggleLoader');
            assignmentService.getAcceptedFilesList().then(response => {
                this.assignments_accpeted_files = response.data;
            }).catch((err) => {
            }).finally(() => {
                this.$bus.emit('toggleLoader');
                this.isBusy = false;

            });
        },
        getAssignmentsQuestions() {
            this.isBusy = true;
            this.$bus.emit('toggleLoader');
            assignmentQuestionService.paginate(this.search, this.page).then(response => {
                this.assignment_questions = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            }).catch((err) => {
            }).finally(() => {
                this.$bus.emit('toggleLoader');
                this.isBusy = false;

            });
        },
        getSingleAssignment(assignment_id) {
            this.isBusy = true;
            this.$bus.emit('toggleLoader');
            assignmentService.show(assignment_id).then(response => {
                this.assignment = response.data.assignment;
                this.getSingleLesson(this.assignment.lesson_planner_id);
                if (this.assignment.allow_submission_date){
                    this.assignment.allow_submission_date = this.$moment(this.assignment.allow_submission_date).toISOString();
                }
                if (this.assignment.due_date){
                    this.assignment.due_date = this.$moment(this.assignment.due_date).toISOString();
                }
                if (this.assignment.cut_off_date){
                    this.assignment.cut_off_date = this.$moment(this.assignment.cut_off_date).toISOString();
                }
                if (this.assignment.grading_reminder_date){
                    this.assignment.grading_reminder_date = this.$moment(this.assignment.grading_reminder_date).toISOString();
                }

                if (this.assignment.accepted_files) {
                    this.assignment.accepted_files = this.assignment.accepted_files.split(',');
                }
                if (this.assignment.feedback_types) {
                    this.assignment.feedback_types = this.assignment.feedback_types.split(',');
                } else this.assignment.feedback_types = [];
            }).catch((err) => {
            }).finally(() => {
                this.$bus.emit('toggleLoader');
                this.isBusy = false;

            });
        },
        getPrograms() {
            programService.all().then(response => {
                this.programs = response.data;
            });
        },

        getGrades() {
            gradeLevelService.getByProgram(this.search.program_id).then(response => {
                this.gradesLevels = response.data;
            });
        },

        getCourses() {
            courseService.paginate(this.search).then(response => {
                this.courses = response.data.data;
            });
        },
        formatType(type){
            if (type && type.includes("_"))
                return type.split('_').join(" ");
            else
                return  type;
        }
    },
}
