import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AssignmentSubmissionService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/assignment`;
      }

      paginate(assignmentId,data = null, index = null) {
            let url = `${this.#api}/${assignmentId}/submission`;
            if (data != null && data != undefined && data != "") {
                  var queryString = Object.keys(data).map((key) => {
                        if (data[key] && data[key] != null)
                              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
                  }).join('&');
                  url = url + '?' + queryString;
            }
            if (index != null)
                  url = url + '?' + "page=" + (index);
            return apiService.get(url);
      }

      update(assignmentId,id, data) {
            let url = `${this.#api}/${assignmentId}/submission/${id}`
            return apiService.put(url, data);

      }

      store(assignmentId, data) {
            let url = `${this.#api}/${assignmentId}/submission`
            return apiService.post(url, data);

      }

      show(assignmentId, id) {
            let url = `${this.#api}/${assignmentId}/submission/${id}`
            return apiService.get(url);
      }
      getByAssignmentAndStudentId(assignmentId, student_id) {
            let url = `${this.#api}/${assignmentId}/submission/${student_id}/assignment-submission`
            return apiService.get(url);
      }
      sort( assignmentId, data) {
            let url = `${this.#api}/sort`;
            return apiService.post(url, data);
      }


      delete(assignmentId, id) {
            let url = `${this.#api}/${assignmentId}/submission/${id}`
            return apiService.delete(url);
      }
}
