import Apiservice from '@/core/services/api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;

export default class CoursesService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/courses`;

    }

    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    paginate(data, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getInternalCourses(programId){
        let url = `${this.#api}/${programId}/internal/courses`
        return apiService.get(url);
    }

    getByProgram(programId, data = {}) {
        let url = `${this.#api}/${programId}/program`
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getByProgramIds(programIds) {
        let url = `${this.#api}/${programIds}/program-unique`
        return apiService.get(url);
    }

    getOptionalCourseByProgramAndGrade(programId, gradeId) {
        let url = `${this.#api}/${programId}/grade-optional/${gradeId}/courses`
        return apiService.get(url);
    }
    getCourseByGrade(gradeId) {
        let url = `${this.#api}/${gradeId}/grade`
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    createMultipleCourse(data) {
        let url = `${this.#api}/create/multiple/course`;
        return apiService.post(url, data);
    }
}
